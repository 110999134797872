.features
  padding: 4rem 1rem
  @screen sm
    padding: 4rem
  @screen lg
    padding: 6rem
  .grid--column
    display: flex

  &--wrapper
    background: $c_neutral

  &--feature
    width: 100%
    border: 1px solid $c_white
    background: $c_neutral
    padding: 2rem 1.5rem 1.5rem
    transition: all 0.3s ease-in-out
    @screen sm
      padding: 3rem 1.5rem 2.5rem

    // &:hover
    //   background: $c_white
      // border-color: transparent
      // box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2)

    &-icon
      text-align: center
      margin-bottom: 1rem
      svg
        display: inline-block
        path
          fill: $c_secondary


    &-title
      width: 100%
      +f__headline
      text-align: center
      color: $c_secondary
      margin-bottom: 1rem
      // position: relative
      display: inline-block

    &-content
      text-align: center
      +centered(25rem)
      @extend %font-size__s
