.references

  &--wrapper
    // background: $c_neutral
    padding-right: 1rem
    position: relative
    @screen sm
      padding-right: 2rem

  &--slider
    background: $c_tertiary

  &--reference
    color: $c_white
    padding: 2rem 0 5rem
    @screen sm
      padding: 4rem 0 2rem

    &-quoted
      @extend %font-size__l
      color: $c_orange
      +f__headline
      padding: 0 1rem
      margin-bottom: 1rem
      @screen sm
        padding: 0 2rem
        margin-bottom: 2rem
    &-content
      @extend %font-size__xl
      hyphens: auto
      max-width: 76rem
      padding: 0 2rem 2rem 2rem
      @screen sm
        padding: 0 4rem 3rem 4rem
      @screen lg
        padding: 0 6rem 4rem 6rem

      &.has-image
        @apply pl-8
