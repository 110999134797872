.person
  // background: $c_green
  color: $c_white

  &--wrapper
    padding: 3rem 1rem
    @screen sm
      padding: 4rem 4rem
    @screen lg
      padding: 4rem 6rem

    a
      color: $c_primary
      transition: color 0.3s ease-in-out
      &:active,
      &:hover
        color: $c_orange


  &--left
    @screen sm
      display: flex
      align-items: flex-start

  &--portrait
    width: 8rem
    margin-bottom: 1rem
    @screen lg
      width: 10rem
    @screen 2xl
      width: 12rem
    @screen sm
      flex-grow: 0
      flex-shrink: 0
      width: 12rem
      margin-right: 1.5rem
    &-inner
      border-bottom: 0.5rem solid $c_primary
      @screen sm
        border-bottom-width: 0.75rem

  &--contact
    &-name
      +f__headline
    &-mail,
    &-phone
      a
        color: $c_white
