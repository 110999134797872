.form--item
  margin-bottom: 2rem
  @screen sm
    margin-bottom: 2rem
  label
    display: block
    margin-bottom: 0.5rem
    +f__headline
    // font-style: italic
    // font-weight: bold


.form--text
  @extend %font-size__m
  +f__headline
  color: $c_white
  border: none
  border-bottom: 1px solid $c_primary
  padding: 0.75rem 0.75rem 0.6rem 0.75rem
  width: 100%
  display: block
  margin-bottom: 0.5rem

  background: rgba(255, 255, 255, 0.1)
  transition: background-color 0.3s ease-in-out
  -webkit-appearance: none
  border-radius: 0

  &:hover,
  &:focus
    outline: none !important
    background: rgba(255, 255, 255, 0.2)

.form--textarea
  @extend %font-size__m
  +f__headline
  color: $c_white
  border: none
  border-bottom: 1px solid $c_primary
  width: 100%
  resize: none
  display: block
  padding: 0.7rem 0.75rem 0.75rem 0.75rem
  margin-bottom: 0.5rem
  resize: none

  background: rgba(255, 255, 255, 0.1)
  transition: background-color 0.3s ease-in-out
  -webkit-appearance: none
  border-radius: 0

  &:hover,
  &:focus
    outline: none !important
    background: rgba(255, 255, 255, 0.2)


// .form--select-wrapper
//   position: relative
//   background: $c_white
//   border: none
//   border-bottom: 1px solid $c_primary

//   select
//     width: 100%
//     height: 100%
//     -moz-appearance: none
//     -webkit-appearance: none
//     appearance: none
//     border: none
//     cursor: pointer
//     border-radius: 0
//     padding: 0.7rem 0.75rem 0.75rem 0.75rem !important
//     text-overflow: ellipsis
//     color: $c_black
//     background: transparent
//     position: relative
//     z-index: 2
//     font-weight: normal

//     &,
//     option
//       +f__copy
//       font-size: 1rem

//     &::-ms-expand
//       display: none

//   .form--select-deco

//     select:active ~ &
//       background: red

//     position: absolute
//     top: 50%
//     transform: translateY(-50%)
//     right: 1rem
//     svg
//       display: block
//       polyline,
//       path
//         stroke: var(--color-primary) !important


.form--checkbox
  margin-bottom: 0.5rem

  label
    @extend %font-size__s
    display: block !important
    padding-left: 2rem
    position: relative
    user-select: none
    text-align: left
    font-weight: normal !important

    a
      color: $c_primary
      transition: color 0.3s ease-in-out
      &:active,
      &:hover
        color: $c_orange

    input[type="checkbox"]
      position: absolute
      top: 0
      left: 0
      width: 18px
      height: 18px
      border: 1px solid $c_white
      background: none
      -webkit-appearance: none
      border-radius: 0

      &:checked
        background: $c_white

.form--submit
  @extend %button


.uniform__potty
  position: absolute
  top: -1111rem
  left: -1111rem

.uniform-errors
  margin-top: 2rem
  margin-bottom: 2rem
  color: $c_error


.form--hp
  position: absolute
  left: -111111em


.form--success
  padding: 1rem
  background: rgba(255, 255, 255, 0.25)
  color: $c_white
  +f__headline
  p
    margin-bottom: 2rem
    &:last-child
      margin-bottom: 0

.form--alert
  padding: 1rem
  background: $c_error
  color: $c_white
  +f__headline
