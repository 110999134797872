.text

  .editor--content

    @extend %font-size__l
    letter-spacing: 0.05em
    font-weight: normal
    +f__headline

    h3,
    .h3
      +f__copy
      @extend %font-size__s
      font-weight: normal
      text-align: left

    p
      margin-bottom: 2rem


  .fold--inner
    +centered(32rem)
    @apply px-8 sm:px-0
    @screen lg
      +centered(46rem)



  //
  // ANIMATIONS
  //
  &--inner
    h3
      margin-bottom: 1rem
      @screen sm
        margin-bottom: 0
        padding-right: calc(80% + 1rem)
      @screen lg
        padding-right: calc(85% + 1rem)

    p,
    ul,
    ol
      padding-left: 0
      @screen sm
        padding-left: 20%
      @screen md
        padding-left: 15%




    h3,
    p,
    ul,
    ol
      opacity: 0
      transition: opacity 0.8s ease-in-out
      transition-delay: 0

      @for $i from 1 through 10
        &:nth-child(#{$i})
          transition-delay: (0.1s * $i) - 0.2s

    h3
      transition: all 0.8s ease-in-out
      transform: translateY(5vh)
      @screen sm
        transform: translateY(130%) translateX(-1.5rem)

    p,
    ul,
    ol
      transform: translateY(5vh)
      transform-origin: 50% top
      transition: all 0.8s ease-in-out


  &.fullpage--section.down
    p,
    ul,
    ol
      transform: translateY(-5vh)
      transform-origin: 50% bottom

    h3
      transform: translateY(-5vh)
      transform-origin: 50% bottom
      @screen sm
        transform: translateY(0)

  &.fullpage--section.up
    h3,
    p,
    ul,
    ol
      @for $i from 1 through 10
        &:nth-child(#{$i})
          transition-delay: 2s - ((0.2s * $i) + 0.8s)

  &.fullpage--section.active
    h3,
    p,
    ul,
    ol
      opacity: 1
      transition-delay: 0.4s
      transition-duration: 0.8s

      @for $i from 1 through 10
        &:nth-child(#{$i})
          transition-delay: (0.2s * $i) + 0.2s

    h3
      transform: translateY(0)
      @screen sm
        transform: translateY(130%) translateX(0)

    p,
    ul,
    ol
      transform: translateY(0)
