.hero
  @apply h-screen
  max-height: 50rem
  overflow: hidden

  margin-top: -3.5rem
  @screen sm
    margin-top: -7.5rem

  &--wrapper
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: flex-start
    position: relative

  &--deco
    position: absolute
    z-index: 10

    &-1
      top: 25%
      width: 4vw
      min-width: 4rem
      max-width: 6rem
      right: 60%
      @screen sm
        right: 40%
      svg
        display: block
        width: 100%
        height: auto
        path
          fill: $c_orange
    &-2
      right: 30%
      width: 2vw
      min-width: 2rem
      max-width: 2.5rem
      top: 35%
      @screen sm
        top: 70%
      svg
        display: block
        width: 100%
        height: auto
        path
          fill: $c_yellow
    &-3
      top: 50%
      right: 0
      margin-right: -1vw
      width: 12vw
      min-width: 8rem
      max-width: 24rem
      svg
        display: block
        width: 100%
        height: auto
        path
          fill: $c_green


  &--img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 5
    object-fit: cover

  &--title
    position: relative
    z-index: 30
    +f__headline
    @extend %font-size__hero-title
    color: $c_primary
    padding: 0 1rem 1rem 1rem
    @screen sm
      padding: 0 2rem 4rem 2rem

  &--bottom
    color: $c_white
    position: relative
    z-index: 40
    // font-weight: bold
    @extend %font-size__l
    justify-self: flex-end
    max-width: 76rem
    hyphens: auto

    padding: 0 1rem 2rem 1rem
    @screen sm
      padding: 0 4rem 6rem 4rem
    @screen lg
      padding: 0 6rem 6rem 6rem
