.links
  display: flex
  flex-direction: column
  align-items: center

  @screen sm

    justify-content: center
    flex-direction: row
    &.links--count-2
      flex-wrap: none
      align-items: center
      justify-content: space-between

  .links--link
    &,
    a
      display: inline-block

  &.links--count-2.links--has-testimonial
    .links--link-1
      padding: 2rem 3rem
    .links--link-2
      padding: 3rem 2rem
    .links--link
      @screen sm
        padding: 0
        transform: translateY(-100%)

  .links--link-1
    order: 1
  .testimonial
    order: 2
  .links--link-2
    order: 3


  .button
    @screen sm
      margin-right: 2rem
      margin-left: 2rem
