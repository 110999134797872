.img-full
  img,
  picture,
  svg
    @apply block w-full h-auto

.image

  // &--wrapper
  //   margin-bottom: 2rem
  //   display: flex
  //   justify-content: flex-start

  // &--image
  //   @screen md
  //     .image--orienation__landscape &
  //       margin-left: -5.5rem
  //       margin-right: -5.5rem
  //     .image__float &
  //       margin-left: 0
  //       margin-right: 0

  // &__float
  //   float: left
  //   width: 50%
  //   margin-left: 0
  //   margin-right: 2rem
  //   margin-bottom: 0.5rem
  //   .base--centered__default,
  //   .base--centered__medium
  //     padding-left: 0

  // &--orienation
  //   &__portrait
  //     .image--inner
  //       width: 50%
  //     &.image__float
  //       .image--inner
  //         width: auto
