.accordion

  .ac
    border-bottom: 1px solid $c_border
    &:first-child
      border-top: 1px solid $c_border
    @screen sm
      margin-left: -1rem
      margin-right: -1rem

  .ac-q
    @extend %button-reset
    @extend %font-size__l
    text-transform: uppercase
    letter-spacing: 0.15em
    color: $c_black
    padding-left: 0
    position: relative
    font-weight: bold
    text-align: left
    width: 100%
    padding: 1.25rem 3rem 1rem 0
    cursor: pointer
    @screen sm
      padding: 2.5rem 3rem 2rem 1rem

    &:focus,
    &::-moz-focus-inner
      border: none
      outline: none !important

    .accordion--trigger
      display: block
      position: absolute
      z-index: 1
      transform-origin: 50% 50%
      transition: transform 0.2s ease-in-out
      width: 2rem
      height: 2rem
      svg
        display: block
        width: 100%
        height: auto
      top: 0.85rem
      right: 0.25rem
      padding: 0.25rem
      @screen sm
        top: 2.15rem
        right: 0.5rem
        padding: 0

      &:after
        content: ''
        position: absolute
        background: transparent
        top: 0
        left: 0
        width: 100%
        height: 100%

      svg
        display: block

    &[aria-expanded=true]
      .accordion--trigger
        transform: rotate(45deg)

  .ac-a
    overflow: hidden
  .ac-a-inner
    // padding: 1rem 0
    // @screen sm
    //   padding: 3rem 0

  .ac
    // border-bottom: 1px solid $c_border
    &.is-active
      padding-bottom: 2rem
    &.is-active,
    &.js-enabled
      transition-delay: 0s !important
    &:last-child
      border-bottom: 1px solid $c_border


  // .ac-a:last-of-type
  //   border-bottom-color: $c_black

  // .ac-q
  //   &[aria-expanded=true]
  //     border-bottom-color: $c_border
  .ac-a
    &[aria-hidden=true]
      display: none
