@import "./settings"

%reset-button,
%button-reset
  border: none
  background: none
  outline: none !important

%button
  outline: none !important
  display: inline-block
  +f__headline
  @extend %font-size__m
  // font-weight: bold
  position: relative
  overflow: hidden
  padding: 0.45rem 3rem 0.5rem 3rem
  color: $c_white
  background: $c_primary
  border: none
  text-decoration: none !important
  transition: all 0.2s ease-in-out
  will-change: background
  text-align: center

  &:hover,
  &:active
    background: $c_orange
    cursor: pointer
  &__wide
    text-align: center
    width: 100%

  &__secondary
    background: none
    color: $c_primary
    border: 2px solid
    transition: color 0.2s ease-in-out


%text-transform__up
  text-transform: uppercase
  letter-spacing: 0.05em

%f__copy
  +f__copy

// FONT SIZE
%font-size__navigation
  font-size: 1.6rem // 40px
  line-height: 1.8rem
  @screen sm
    line-height: 2.3rem
    font-size: 2.1rem
%font-size__navigation-footer
  font-size: 1rem
  line-height: 1.125rem
  @screen sm
    font-size: 1.125rem
    line-height: 1.25rem

%font-size__navigation-meta
  font-size: 0.85rem
  line-height: 1rem
  @screen sm
    font-size: 1.125rem
    line-height: 1.25rem

%font-size__button
  font-size: 1rem
  line-height: 1rem
  @screen sm
    font-size: 1.125rem
    line-height: 1.125rem

%font-size__xs
  +fontSize(14px, 18px)
  @screen sm
    +fontSize(18px, 24px)

%font-size__s
  +fontSize(16px, 20px)
  @screen sm
    +fontSize(20px, 26px)

%font-size__m
  +fontSize(18px, 22px)
  @screen sm
    +fontSize(24px, 30px)

%font-size__l
  +fontSize(20px, 24px)
  @screen sm
    +fontSize(26px, 32px)

%font-size__xl
  +fontSize(24px, 30px)
  @screen sm
    +fontSize(40px, 46px)

%font-size__xxl
  +fontSize(60px, 68px)
  @screen sm
    +fontSize(94px, 104px)

%font-size__hero-title
  font-size: 6vh
  line-height: 7vh
  @screen lg
    +fontSize(94px, 104px)

%font-size__navigation-main
  +fontSize(40px, 50px)
  @screen sm
    +fontSize(60px, 70px)

%bigtarget
  &:after
    content: ''
    +stretch(0, 0, 0, 0)
    text-indent: -1111em
    font-size: 0
    line-height: 0
    z-index: 1

%bigtarget__expanded
  &:after
    content: ''
    +stretch(-111rem, -1111rem, -111rem, -1111rem)
    text-indent: -1111em
    font-size: 0
    line-height: 0
    z-index: 1
