.footer
  @extend %font-size__s
  background: $c_white
  color: $c_base-text
  @appyl border border-t-2 border-theme-secondary
  padding: 4rem 1rem 2rem 1rem
  margin-top: 2rem
  @screen sm
    padding: 4rem 2rem 2rem 2rem
    margin-top: 4rem


  &--logo
    svg
      path
        fill: $c_secondary
    @apply w-24 sm:w-48
    margin-bottom: 2rem
    svg
      display: block
      width: 100%
      height: auto

  &--inner
    @screen sm
      min-width: 65%
      display: flex


  &--navigation,
  &--address,
  &--copright
    margin-bottom: 2rem


  @screen sm
    display: flex
    flex-wrap: wrap
    justify-content: space-between

    &--navigation,
    &--address,
    &--copright
      margin-bottom: 0
      margin-right: 4rem
