.contact
  background: $c_green
  color: $c_white

  &--wrapper
    @apply py-16 px-4 md:p-16 lg:p-24

  &--headline
    @extend %font-size__xl
    +f__headline
    color: $c_primary

  &--subline
    @extend %font-size__xl
    margin-bottom: 1rem
    @screen sm
      margin-bottom: 2rem
