.demo
  display: flex
  flex-wrap: wrap
  padding: 4rem 1rem
  @screen sm
    padding: 4rem
  @screen lg
    padding: 6rem


  &--wrapper
    background-image: linear-gradient(180deg, $c_secondary 70%, $c_neutral 70%)


  &--headline
    width: 100%
    @extend %font-size__xl
    +f__headline
    @apply text-theme-primary
    margin-bottom: 1.5rem
    @screen sm
      margin-bottom: 3rem

  &--text
    width: 100%
    @apply pb-8 lg:pb-0 text-theme-white lg:bg-none
    @screen lg
      width: 40%

    @apply text-theme-white
    ul
      li
        &:before
          @apply bg-theme-orange

  &--image
    position: relative
    @apply w-full lg:w-[60%]

    .badge
      position: absolute
      top: 0
      right: 0
      transform: translate(0, -50%)
