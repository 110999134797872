$c_white: rgb(255, 255, 255)
$c_black: rgb(0, 0, 0)

// türkis
$c_primary: #009ee2
$c_turquoise: $c_primary

// lila
$c_secondary: #312782
$c_purple: $c_secondary

// lila 2
$c_tertiary: #431254
$c_purple_2: $c_tertiary

$c_neutral: #e6e6e6

$c_orange: #e84e1b
$c_yellow: #d8fa2c
$c_green: #004a59

$c_base-text: #666666
$c_border: $c_base-text

$c_positive: $c_secondary
$c_negative: $c_orange

$c_success: $c_positive
$c_status: $c_primary
$c_warning: rgb(255,160,0)
$c_error: $c_negative

// FONTS
$f__headline: "GTWalsheim"
$f__copy: "EBGaramond"


=f__headline
  font-family: $f__headline, "sans serif"
=f__copy
  font-family: $f__copy, "serif"

$default-transition-duration: .4s
$default-transition-function: ease-in-out

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
// $mq-breakpoints: (mobile: 560px, mobile_landscape: 680px, tablet: 769px, desktop: 1024px, wide: 1400px, ultra: 1800px)

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $mq-responsive is set to 'false').
// $mq-static-breakpoint: desktop
$mq-base-font-size: 16px !default

$centered_default_max_width: 70rem !default
$default_border_radius: 0 !default

$layout_default_gutter_width: 2rem !default

$width_centered_wide: 96rem
$width_centered_default: 50rem
$width_centered_medium: 40rem
$width_centered_narrow: 28rem
