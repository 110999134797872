.navigation

  &--header
    @apply opacity-0 invisible xl:opacity-100 xl:visible
    @apply transition-[opacity,visibility] duration-300 ease-in
    .header.navigation__active &
      @apply opacity-100 visible

    &-link
      font-size: 2.5rem
      line-height: 3.125rem
      @screen md
        font-size: 3.75rem
        line-height: 4.375rem
      @screen xl
        +fontSize(24px, 30px)


  &--footer
    &-main-link
      @extend %font-size__m


  &--trigger
    @apply relative z-[100] h-12 font-bold text-white border-none outline-none
    @apply opacity-100 transition-opacity duration-200
    +f__copy
    @extend %font-size__m
    padding: 0 2.5rem 0 0
    @screen sm
      padding: 0 3rem 0 0

    // .header.loading &
    //   opacity: 0

    @apply after:content-[''] after:absolute after:z-30 after:top-0 after:left-0 after:w-full after:h-full

    &--deco-01,
    &--deco-02,
    &--deco-03,
    &--deco-04
      @apply block absolute z-20 right-0 top-4 sm:top-[0.7rem] h-[3px] sm:h-1 w-6 sm:w-8
      @apply bg-theme-primary transition-all duration-200 origin-center

    &--deco-02,
    &--deco-03
      top: 1.5rem
      @screen sm
        top: 1.45rem
    &--deco-04
      top: 2rem
      @screen sm
        top: 2.15rem

    .navigation__active &
    .navigation__active &
      &--deco-01
        transform: translateY(-1rem)
        opacity: 0
      &--deco-04
        transform: translateY(1rem)
        opacity: 0
      &--deco-02
        transform: rotateZ(45deg)
      &--deco-03
        transform: rotateZ(-45deg)
