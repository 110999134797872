@mixin centered($maxWidth: $centered_default_max_width)
  width: 100%
  // padding-left: $layout_default_gutter_width/2
  // padding-right: $layout_default_gutter_width/2
  @screen sm
    margin-left: auto
    margin-right: auto
    // max-width: $maxWidth + $layout_default_gutter_width
  max-width: $maxWidth
  @screen md
    // max-width: $maxWidth + ($layout_default_gutter_width*2)
    max-width: $maxWidth
    // padding-left: $layout_default_gutter_width
    // padding-right: $layout_default_gutter_width
  @media print
    max-width: 90% !important

@mixin stretch($offset-top: 0, $offset-right: 0, $offset-bottom: 0, $offset-left: 0)
  position: absolute
  @if $offset-top
    top: $offset-top
  @if $offset-bottom
    bottom: $offset-bottom
  @if $offset-left
    left: $offset-left
  @if $offset-right
    right: $offset-right

@mixin pie-clearfix
  &:after
    content: ""
    display: table
    clear: both

@mixin underline($color: #fff)
  display: inline
  // line-height: 2
  // background-image: linear-gradient(90deg, $color, $color)
  // background-size: auto 0.2em
  // background-repeat: repeat-x
  // background-position: 0 1.75em
  // transition: background-position 0.3s ease-in-out
  padding-bottom: 0.25em
  text-decoration: none
  background-size: 100% 100%
  background-repeat: no-repeat
  background-image: linear-gradient(transparent calc(100% - 0.075em), $color 0.075em)
  @screen sm
    background-image: linear-gradient(transparent calc(100% - 0.15em), $color 0.15em)


// Function for converting a px based font-size to rem.
@function calculateRem($size)
  $remSize: $size / $mq-base-font-size
  //Default font size on html element is 100%, equivalent to 16px;
  @return $size / $mq-base-font-size * 1rem

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($fontSize, $lineHeight)
  font-size: calculateRem($fontSize)
  line-height: calculateRem($lineHeight)
