@import "./mixins"
@import "./extends"
@import "./settings"

.editor
  &--content
    @extend %font-size__m
    text-align: left

    &__small,
    &__s
      @extend %font-size__s
    &__l
      @extend %font-size__l
    &__xl
      @extend %font-size__xl

    a
      color: $c_black
      font-weight: bold
      text-decoration: none
      color: $c_black
      transition: color 0.3s ease-in-out
      &:active,
      &:hover
        color: $c_secondary

    .background__lavendel &
      &,
      a
        color: $c_white
        &:active,
        &:hover
          color: $c_white

    .font-size__s
      @extend %font-size__s
    .font-size__l
      @extend %font-size__l
    .font-size__xl
      @extend %font-size__xl

    .text-transform__uppercase
      text-transform: uppercase
      letter-spacing: 0.15em

    h1,
    .h1
      @extend %font-size__xl
      color: $c_primary
      +f__headline
      hyphens: auto
      margin-bottom: 1rem
      @screen sm
        margin-bottom: 1rem


    h2,
    .h2
      @extend %font-size__l
      font-weight: bold
      margin-bottom: 0.5rem
      @screen sm
        margin-bottom: 1rem

    h3,
    .h3
      @extend %font-size__m
      font-weight: bold

    ol,
    ul
      padding-left: 0.9rem
      @screen sm
        padding-left: 1.25rem
      // li
      //   margin-bottom: 0.5rem

    ol
      margin-bottom: 2rem
      list-style: decimal outside
      li
        padding-left: 1rem

    ul
      margin-bottom: 2rem
      padding-left: 0
      list-style: none
      li
        padding-left: 1em
        position: relative
        margin-bottom: 1rem
        &:before
          position: absolute
          top: 0.5em
          left: 0
          content: ''
          width: 0.5rem
          height: 0.5rem
          border-radius: 50%
          background: $c_neutral

    p
      margin: 0 0 3rem 0

    strong
      font-weight: bold
    em
      font-style: italic

    hr
      border: none
      background: $c_black
      height: 1px
      margin: 3rem 0
      @screen sm
        margin: 4rem -8rem

  &--backlink
    display: block
    font-weight: bold
    @screen sm
      margin-top: 6rem
    span
      margin-left: 1rem
      text-decoration: underline
