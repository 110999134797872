.badge
  border-radius: 50%
  background: $c_yellow
  +f__headline
  @extend %font-size__xs
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  color: $c_secondary

  flex-shrink: 0
  width: 6rem
  height: 6rem
  @screen sm
    width: 8rem
    height: 8rem

  &--inner
    hyphens: auto
    width: 80%

    p
      margin: 0


  &--color
    &__primary
      background: $c_primary
      color: $c_white
    &__secondary
      background: $c_secondary
      color: $c_white
    &__orange
      background: $c_orange
      color: $c_white


  &--size
    &__small
      width: 4rem
      height: 4rem
      @screen sm
        width: 6rem
        height: 6rem
    &__big
      width: 8rem
      height: 8rem
      @screen sm
        width: 10rem
        height: 10rem
    &__giant
      width: 12rem
      height: 12rem
      @screen sm
        width: 16rem
        height: 16rem
