// @import "./reset";
@import "./settings";

@import 'tailwindcss/base';
@import 'custom-base.scss';

@import 'tailwindcss/components';
@import 'custom-components.scss';

@import 'tailwindcss/utilities';
@import 'custom-utilities.scss';

@import "./vendor/tiny-slider.css";

@import "./base";
@import "./grid";
// @import "./layout";
@import "./fonts";
@import "./fold";
@import "./editor";
@import "./form";
@import "./header";
@import "./navigation";
@import "./button";
@import "./text";
@import "./image";
@import "./hero";
// @import "./teaser";
@import "./reveal";
@import "./links";
// @import "./table";
@import "./contact";
// @import "./pagetransition";
@import "./econio-accordion";
@import "./mobile";
@import "./footer";
@import "./slider";
@import "./facts";
@import "./features";
// @import "./featuretable";
@import "./demo";
@import "./tiles";
@import "./references";
@import "./contact";
@import "./person";
@import "./badge";
