@font-face
  font-family: "GTWalsheim"
  src: url("/assets/fonts/GT-Walsheim-Medium.woff") format("woff"), url("/assets/fonts/GT-Walsheim-Medium.woff2") format("woff2")
  font-weight: normal
  font-style: normal
  font-display: block

@font-face
  font-family: "EBGaramond"
  src: url("/assets/fonts/EBGaramond-Regular.woff") format("woff"), url("/assets/fonts/EBGaramond-Regular.woff2") format("woff2")
  font-weight: normal
  font-style: normal
  font-display: block

@font-face
  font-family: "EBGaramond"
  src: url("/assets/fonts/EBGaramond-Bold.woff") format("woff"), url("/assets/fonts/EBGaramond-Bold.woff2") format("woff2")
  font-weight: bold
  font-style: normal
  font-display: block
