.facts
  padding: 4rem 1rem
  @screen sm
    padding: 4rem
  @screen lg
    padding: 6rem
  .grid--column
    display: flex

  &--wrapper
    background: $c_neutral

  &--fact
    background: $c_white
    padding: 1rem 1.5rem 2rem

    &-title
      +f__headline
      color: $c_secondary
      margin-bottom: 1.5rem
      position: relative
      display: inline-block
      &:after
        content: ''
        background: $c_primary
        position: absolute
        bottom: -0.8rem
        left: -0.25rem
        width: calc(100% + 0.25rem)
        height: 0.15rem
        @screen sm
          height: 0.25rem

    &-content,
    &-link
      @extend %font-size__s
