.grid

  &--wrapper
    @apply grid grid-cols-12
    @apply gap-x-4 gap-y-6 lg:gap-6

    &__flat
      @apply gap-4

  &--column
    @apply col-span-12
    &[data-width-mobile="1/1"]
      @apply col-span-12
    &[data-width-mobile="1/2"]
      @apply col-span-6

    @screen md
      &[data-width="1/1"]
        @apply col-span-12
      &[data-width="1/2"]
        @apply col-span-6
      &[data-width="1/3"]
        @apply col-span-6
      &[data-width-tablet="1/1"]
        @apply col-span-12

    @screen lg
      &[data-width="1/1"]
        @apply col-span-12
      &[data-width="1/2"]
        @apply col-span-6
      &[data-width="1/3"]
        @apply col-span-4
