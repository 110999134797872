.tiles
  display: flex
  flex-wrap: wrap
  padding: 4rem 1rem
  @screen sm
    padding: 4rem
  @screen lg
    padding: 6rem


  &--wrapper
    background: $c_neutral


  &--tile
    height: 100%
    color: $c_white
    border: 1px solid $c_white
    padding: 1rem 1.5rem 1rem
    @screen sm
      padding: 1rem 1.5rem 1rem

    &__primary
      background: $c_primary
    &__secondary
      background: $c_secondary
    &__green
      background: $c_green

    &-icon
      text-align: center
      svg
        display: inline-block
        width: 4rem
        height: auto
        path
          fill: $c_white


    &-title
      width: 100%
      +f__headline
      text-align: center
      color: $c_white
      margin-bottom: 2rem
      position: relative
      // display: flex
      // align-items: center

    &-text
      ul
        li
          &:before
            background: $c_white

    &-badges
      display: flex
      padding: 2rem 0 2rem 2rem

      .badge
        margin-left: -0.5rem
        margin-right: -0.5rem
        @screen sm
          margin-left: -1rem
          margin-right: -1rem

  &--footnotes
    @extend %font-size__xs
