@import "./mixins"
@import "./extends"
@import "./settings"

*
  box-sizing: border-box

html
  font-size: $mq-base-font-size
  color: black
  margin: 0
  padding: 0


body
  color: $c_base-text
  font-family: $f__copy
  @extend %font-size__m
  margin: 0
  padding: 0
  // overflow-x: hidden

  background: $c_white
  // &.is-home
  //   background: $c_secondary

ul,
ol
  &,
  li
    margin: 0
    padding: 0

img
  display: block
  width: 100%
  height: auto

a
  text-decoration: none

.base
  &--container
    min-height: 100vh
    display: flex
    flex-direction: column
    // @apply overflow-x-hidden

  &--header
    // padding-top: 1rem
    // padding-bottom: 4rem

  &--header,
  &--footer
    display: block
    width: 100%
    flex-grow: 0
    flex-shrink: 0
    min-height: 1rem

  &--main-wrapper
    width: 100%
    position: relative
    z-index: 1
    display: flex
    flex-direction: column
    flex-grow: 1
    flex-shrink: 1
    min-height: 1rem

  &--main
    // background: $c_secondary
    flex-grow: 1
    flex-shrink: 1
    min-height: 1rem
    width: 100%
    position: relative
    z-index: 1

    // padding-top: 3.5rem
    // @screen sm
    //   padding-top: 7.5rem



    &__default
      padding: 6rem 1rem 3rem 1rem
      @screen sm
        padding: 15rem 0 7.5rem 0
        padding-bottom: 7.5rem


  &--wrapper
    width: 100%
    +centered()
    // padding-top: 5rem
    // border-color: $c_white !important


  &--bordered
    border: 1px solid $c_border
    &__top
      border-top: 1px solid $c_border
    &__bottom
      border-bottom: 1px solid $c_border

  &--padding
    padding: 1rem
    &__top
      padding-top: 1rem
    &__bottom
      padding-bottom: 1rem

  // &--main
  //   padding-bottom: 5rem
  &--text__invisible
    font-size: 0
    line-height: 0


  &--centered
    +centered()
    &__full
      +centered()
      max-width: 100%
    &__wide
      +centered($width_centered_wide)
    &__default
      +centered($width_centered_default)
    &__medium
      +centered($width_centered_medium)
    &__narrow
      +centered($width_centered_narrow)
    // &__extra-narrow
    //   +centered(32rem)

  @for $i from -5 to 14
    &--bottom__#{$i},
    &--bottom--#{$i}
      margin-bottom: #{$i/2}rem !important
      @screen md
        margin-bottom: #{$i}rem !important
    &--right__#{$i},
    &--right--#{$i}
      margin-right: #{$i/2}rem !important
      @screen md
        margin-right: #{$i}rem !important
    &--left__#{$i},
    &--left--#{$i}
      margin-left: #{$i/2}rem !important
      @screen md
        margin-left: #{$i}rem !important
    &--top__#{$i},
    &--top--#{$i}
      margin-top: #{$i/2}rem !important
      @screen md
        margin-top: #{$i}rem !important

  &--text-align
    &__left
      text-align: left !important
    &__center
      text-align: center !important
    &__right
      text-align: right !important

  &--relative
    position: relative

  &--flex
    @apply text-center md:text-inherit

    @screen md
      display: flex

      &__row
        flex-direction: row
        justify-content: space-between

      &-child
        flex: 1 1

  &--block
    display: block
  &--inline-block
    display: inline-block
    vertical-align: middle

  &--clearfix
    +pie-clearfix

  &--no-margin
    &__bottom
      margin-bottom: 0 !important

  &--no-flex
    display: block !important

  &--translate
    &__top
      @screen md
        transform: translateY(-15%)

  &--bigtarget
    @extend %bigtarget

  &--fullheight
    display: flex
    // height: 100vh
    @apply min-h-screen

  &--background__primary
    background: var(--color-primary)
    color: $c_white
  &--background__secondary
    background: var(--color-secondary)
  &--background__tertiary
    background: var(--color-tertiary)
  &--background__neutral
    background: $c_neutral
  &--background__white
    background: $c_white
  &--background__black
    background: $c_black

  &--nowrap
    white-space: nowrap

  &--text-align
    &__left
      text-align: left
    &__center
      text-align: center
