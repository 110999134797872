.tns-slider
  position: relative

.tns-nav
  position: absolute
  width: 100%
  bottom: 2.45rem
  height: 40px
  z-index: 30
  display: flex
  justify-content: center
  align-items: center

  button
    display: inline-block
    vertical-align: middle
    margin: 0 0.4rem
    width: 0.75rem
    height: 0.75rem
    padding: 0
    border-radius: 50%
    border: 1px solid rgba(255, 255, 255, 0.3)
    background: none

    &:hover,
    &.tns-nav-active
      background: $c_white
      border-color: $c_white

.tns-controls
  position: absolute
  width: 100%
  bottom: 2.5rem
  z-index: 30
  text-align: center
  outline: none !important

  button
    @extend %button-reset
    width: 40px
    height: 40px
    text-indent: -1111rem
    opacity: 0.8
    transition: opacity 0.3s ease-in-out

    &:hover,
    &:active
      opacity: 1

    &[data-controls="prev"]
      background: url("/assets/images/button-prev.svg") no-repeat
      background-size: contain
      @for $i from 1 through 10
        .references--count__#{$i} &
          margin-right: ($i/2) * 2rem
    &[data-controls="next"]
      background: url("/assets/images/button-next.svg") no-repeat
      background-size: contain
      @for $i from 1 through 10
        .references--count__#{$i} &
          margin-left: ($i/2) * 2rem
