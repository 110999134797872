@import "./mixins"
@import "./extends"
@import "./settings"

.header
  width: 100%
  z-index: 100
  position: fixed
  background: $c_secondary
  display: flex
  justify-content: space-between
  align-items: center


  height: 3.5rem
  padding: 0 1rem
  @screen sm
    padding: 0 2rem
    height: 7.5rem

  &--logo
    position: relative
    z-index: 75
    @apply w-24 sm:w-48
    svg
      display: block
      width: 100%
      height: auto
      path
        fill: $c_white
      .logo-deco
        fill: $c_primary
