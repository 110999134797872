[role="button"],
.button
  @extend %button

  &.is-disabled,
  &[disabled]
    opacity: 0.2
    &:hover
      cursor: not-allowed


.button
  &__wide
    @extend %button__wide
